import { createReducer } from '@reduxjs/toolkit'
import { getProject, loadProjectsOverviews } from '../actions'
import { Project, ProjectOverview } from '../../types/projects'

interface ProjectsReducer {
    overviews: ProjectOverview[],
    projects: Project[],
}

const initialState: ProjectsReducer = {
    overviews: [],
    projects: []
}

const projectsReducer = createReducer<ProjectsReducer>(initialState, (builder) => {
    builder.addCase(loadProjectsOverviews.fulfilled, (state, action) => {
        state.overviews = action.payload;
    }),
    builder.addCase(getProject.fulfilled, (state, action) => {
        if(!state.projects)
            state.projects = [];
        state.projects.push(action.payload);
    })
})

export default projectsReducer;
