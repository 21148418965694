import { useLayoutEffect, useState } from "react";

const DEVICE_BREAKPOINT_PX = {
    MOBILE: 768
}

export default function useDeviceLayout(layout: "MOBILE"){
    const isMobileLayout = () => window.innerWidth <= DEVICE_BREAKPOINT_PX[layout];
    const [isMobile, setIsMobile] = useState(isMobileLayout());

    useLayoutEffect(() => {
        function adjustLayout() {
            setIsMobile(isMobileLayout());
        }

        window.addEventListener('resize', adjustLayout);
        return () => window.removeEventListener('resize', adjustLayout);
    }, []);

    return isMobile;
}