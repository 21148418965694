import React, { FC, useEffect } from 'react';
import styles from './News.module.css';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/reducers/store';
import { loadBlogPostsOverviews } from '../../store/actions';
import { BlogPostOverview } from '../../types/blogPosts'
import { ROUTES } from '../../resources/routes-constants';
import { NavLink } from 'react-router-dom';

const News = () => {
   const dispatch = useAppDispatch();
   const posts = useAppSelector(state => state.blogPosts.overviews);
   
   useEffect(() => {
      dispatch(loadBlogPostsOverviews());
   }, [])

   return (
      <div className={styles.news}>
      {
         posts.map(post => <BlogPostCard key={post.id} overview={post}/>)
      }
      </div>
   );
};

export default News;

const BlogPostCard: FC<{overview: BlogPostOverview}> = ({overview}) => {
   return (
      <a className={styles.card} href={`${ROUTES.NEWS}/${overview.urlId}`}>
         <img className={styles.blogCover} src={overview.coverImage.url}/>
         <div className={styles.date}>
            {new Date(overview.createdAt).toLocaleString('lt-LT', { year: "numeric", month: 'long' , day: 'numeric'})}
         </div>
         <div className={styles.blogContent}>
            <h4 className={styles.blogTitle}>{overview.title}</h4>
            <div className={styles.blogSlug}>{overview.slug}</div>
            <div className={classNames("navlink", styles.link)}>
               Skaityti daugiau →
            </div>
         </div>
      </a>
   )
}