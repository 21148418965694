import React, { useEffect } from 'react'
import PageHeader from '../components/PageHeader';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/reducers/store';
import { getBlogPost } from '../store/actions';
import BlogPost from '../components/News/BlogPost';

const BlogPostPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const params = useParams<{urlId:string}>();
    const post = useAppSelector(state => state.blogPosts.blogs?.find(x => x.urlId === params.urlId ));

    useEffect(() => {
        if(!post)
            dispatch(getBlogPost(params.urlId!));
    }, [post])

    if(!post)
        return <></>

    return (
        <>
            <PageHeader header={post.title} imageSrc={post.coverImage.url}/>
            <BlogPost blogPost={post}/>
        </>
    )
}

export default BlogPostPage;
