import { createAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ProjectsApiService from '../services/Projects.service'
import BlogPostsApiService from '../services/BlogPosts.service'
import { BlogPost, BlogPostOverview } from '../types/blogPosts'
import { Project, ProjectOverview } from '../types/projects'

// export const setContents = createAction<string[]>('data/setContents')
// export const getData = createAsyncThunk<void, string, { dispatch: AppDispatch }>('groupedActions/getData', async (string, { dispatch }) => {
//     try {
//         const data: any[] = await axios.get(`https://fakeurl.fake/${string}`).then((response) => response.data.results)
//         dispatch(setContents(data))
//     } catch (e) {
//         console.error(e)
//         throw e
//     }
// })

//HOME
export const init = createAction('home/init')

//NEWS
export const loadBlogPostsOverviews = createAsyncThunk<BlogPostOverview[]>('groupedActions/loadBlogPostsOverviews', async () => {
    try {
        const data: any = await BlogPostsApiService.getBlogPostsOverviews();
        return data.blogPosts as BlogPostOverview[];
    } catch (e) {
        console.error(e)
        throw e
    }
})
export const getBlogPost = createAsyncThunk<BlogPost, string>('blogs/get', async (urlId) => {
    try {
        const data: any = await BlogPostsApiService.getBlogPost(urlId);
        return data.blogPost as BlogPost;
    } catch (e) {
        console.error(e)
        throw e
    }
})



//PROJECTS
export const getProject = createAsyncThunk<Project, string>('projects/get', async (url) => {
    try {
        const data: Project = await ProjectsApiService.getProject(url);
        return data;
    } catch (e) {
        console.error(e)
        throw e
    }
})

export const loadProjectsOverviews = createAsyncThunk<ProjectOverview[]>('groupedActions/loadProjectsOverviews', async () => {
    try {
        const data: ProjectOverview[] = await ProjectsApiService.getProjectsOverviews();
        return data;
    } catch (e) {
        console.error(e)
        throw e
    }
})
