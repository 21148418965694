import React, { useState } from 'react';
import styles from './Slider.module.css';
import { useAppSelector } from '../../store/reducers/store';
import classNames from 'classnames';
import { ROUTES } from '../../resources/routes-constants';

const imgTransitionTime = 5000; //update css also
// interface SliderProps {
//     //props
// }

const Slider = () => {
    const images = useAppSelector(state => state.home.images)!;
    const [shownImageIdx, setShownImageIdx] = useState(0);
    
    const next = () => {
        setShownImageIdx(
            images.length === shownImageIdx+1 ? 0
            : shownImageIdx+1
        );
    }

    setTimeout(next, imgTransitionTime);
    return (
        <div className={styles.slider}>
            {images.map((img, idx) => {
                const className = classNames(styles.image, {[styles.current]: idx === shownImageIdx});
                return <img key={idx} className={className} src={img.src} /> 
            })}
            <div className={styles.hero}>
                <h1 className={styles.header}>Erdvės, kuriose gyvenimas ir <br/> verslas susitinka</h1>
                <a className="navlink" href={ROUTES.PROJECTS}>
                    <span>Žiūrėti projektus</span>
                    <span>→</span>
                </a>
            </div>
        </div>
    );
};

export default Slider;