export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    NEWS: '/naujienos',
    BLOG_POST: '/naujienos/:urlId',
    CONTACTS: '/kontaktai',
    SERVICES: '/paslaugos',
    ABOUT_US: '/apie-mus',
    PROJECTS: '/projektai',
    PROJECT: '/projektai/:urlId',
    PROJECTS_FOR_BUSINESS: '/projektai/verslui',
    PROJECTS_FOR_LIVING: '/projektai/gyvenimui',
}