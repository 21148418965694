import { createReducer } from '@reduxjs/toolkit'
import { getBlogPost, loadBlogPostsOverviews } from '../actions'
import { BlogPost, BlogPostOverview } from '../../types/blogPosts'

interface BlogPostsReducer {
    overviews: BlogPostOverview[],
    blogs: BlogPost[]
}

const initialState: BlogPostsReducer = {
    overviews: [],
    blogs: []
}

const blogPostsReducer = createReducer<BlogPostsReducer>(initialState, (builder) => {
    builder.addCase(loadBlogPostsOverviews.fulfilled, (state, action) => {
        state.overviews = action.payload;
    }),
    builder.addCase(getBlogPost.fulfilled, (state, action) => {
        if(!state.blogs)
            state.blogs = [];
        state.blogs.push(action.payload);
    })
})

export default blogPostsReducer
