import React, { useEffect } from 'react'
import PageHeader from '../components/PageHeader';
import defaultImage from "../resources/images/sendvariomiestas/1_Post-min.jpg"
import Projects from '../components/Projects';
import ContentContainer from '../components/ContentContainer';
import { PROJECT_PURPOSE_TYPE } from '../types/projects';

const ProjectsPage: React.FC = () => {      
    return (
        <>
            <PageHeader header="Projektai" imageSrc={defaultImage} />
            <ContentContainer hasMaxWidth={true}>
            {
                Object.entries(PROJECT_PURPOSE_TYPE).map(([key,value]) => (
                    <div key={key} style={{marginBottom: "40px"}}>
                        <h2 className='lined-header'>{value}</h2>
                        <Projects purpose={value}/>
                    </div>
                ))
            }
            </ContentContainer>
        </>
    )
}

export default ProjectsPage;
