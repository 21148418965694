import React, { FC, ReactNode, useRef } from 'react';
import styles from './Services.module.css';
import classNames from 'classnames';
import ContentContainer from '../ContentContainer';
import useAnimateOnScolledToViewPort from '../../utility/useAnimateOnScolledToViewPort';
import rentalImg from "../../resources/images/rental.jpg"
import administrationImg from "../../resources/images/administration.jpg"
import sanitizeHtml from 'sanitize-html';
   
const SECTIONS = [{
   title: "Komercinių patalpų ir biurų nuoma",
   imgSrc: rentalImg,
   credits: <a href="https://www.vecteezy.com/free-photos/property">Property Stock photos by Vecteezy</a>,
   text: `Mūsų įmonė siūlo gyvenamųjų, komercinių patalpų ir biurų nuomos paslaugas. Siūlome
   rinktis įvairaus ploto erdves, kurias <b>galime pritaikyti</b>, atsižvelgiant į jūsų specifinius
   reikalavimus ir poreikius. <br/> Nepriklausomai nuo to, ar jums reikia <b>gyvenamųjų, biuro patalpų,
   sandėliavimo ar prekybos erdvių</b>, mes esame pasiruošę padėti rasti tinkamą sprendimą,
   kuris atitiktų jūsų asmeninius ar verslo lūkesčius. <br/> <b>Susisiekite su mumis šiandien</b>, kad
   galėtume pradėti bendradarbiauti ir padėti pasirinkti tinkamą erdvę jūsų gyvenimui ar
   verslui. <br/> Kontaktai: <a href="mailto: info@augama.lt"><b>info@augama.lt</b></a> 
   arba tel. <a href="tel:+37067619464"><b>+37067619464</b></a> `
},
{
   
   title: "Turto valdymas ir administravimas",
   imgSrc: administrationImg,
   credits: <a href="https://www.vecteezy.com/free-photos/administration">Administration Stock photos by Vecteezy</a>,
   text: `Augama teikia šias nekilnojamojo turto valdymo ir administravimo paslaugas.
   <ul>
      <li><b>Tarpininkavimas</b> perkant, parduodant ar nuomojant nekilnojamajį turtą;</li>
      <li>Pirkimo-pardavimo <b>dokumentų ruošimas</b>  ir kliento atstovavimas;</li>
      <li>Tarpininkavimas atliekant nekilnojamojo <b>turto vertinimą</b> .</li>
      <li><b>Konsultavimas</b>  visais nekilnojamojo turto klausimais.</li>
      <li>Gyvenamojo ir komercinio nekilnojamo turto <b>administravimas</b>  - nuomos sutarčių
      parengimas, nuomininkų paieška , mokesčių įplaukų stebėjimas ir kontrolė, patalpų
      ploto optimizavimas ir renovavimas.</li>
      <li>Pastatų <b>techninė priežiūra</b> , bei tinkamos pastato eksplotacijos užtikrinimas.</li>
   </ul>`
}]

const options = {
   allowedTags: [ 'b', 'br', 'a', 'ul', 'li' ],
   allowedAttributes: { 'a': [ 'href' ] },
};


const Services = () => {
   return (
      <ContentContainer contentClassName={classNames(styles.services, 'section')}>
      {
         SECTIONS.map((section, idx) => (
            <div className={styles.section} key={idx}>
               <Image credit={section.credits} src={section.imgSrc} idx={idx}/>
               <div className={styles.description}>
                  <h3 className={styles.header}>
                     {section.title}
                  </h3>
                  <div className="text">
                  {
                     section.text.split("<br/>").map((descr, idx) => 
                        <span key={idx} dangerouslySetInnerHTML={{__html: sanitizeHtml(descr, options)}}/>
                     )
                  }
                  </div>
               </div>
            </div>
         ))
      }
      </ContentContainer>
   );
};

const Image: FC<{credit:ReactNode, src: string, idx: number}> = ({credit, src, idx}) => {
   const ref = useRef<HTMLDivElement>(null)
   const animationClass = useAnimateOnScolledToViewPort(ref, idx % 2 !== 0 ? "fadeInRight": "fadeInLeft", false);

   return (
      <div ref={ref} className={classNames(styles.image, animationClass)}>
         <img src={src}/>
         {credit}
      </div>
   )
}

export default Services;