import React, { FC, ReactNode } from 'react';
import styles from './Project.module.css';
import { Project as ProjectType } from '../../../types/projects';
import ContentContainer from '../../ContentContainer';
import sanitizeHtml from 'sanitize-html';
import classNames from 'classnames';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import projectsGallery from '../../../resources/images/projectsGallery';

const options = {
    allowedTags: [ 'b', 'br' ],
    // allowedAttributes: {
    //   'a': [ 'href' ]
    // },
    // allowedIframeHostnames: ['www.youtube.com']
};

const Project: FC<{project: ProjectType}> = ({project}) => {
    const descriptionRows = project.description.split('<br/>');

   return (
        <ContentContainer hasMaxWidth={true} contentClassName={styles.project}>
            <h2 className={styles.header}>Aprašymas</h2>
            <div className={classNames(styles.description, "text")}>
            {
                descriptionRows.map((descr, idx) => 
                    <span key={idx} dangerouslySetInnerHTML={{__html: sanitizeHtml(descr, options)}}/>
                )
            }
            </div>
            <WebsiteWrapper url={project.website} displayChildrenIfNoUrl={true}>{
                projectsGallery[project.imagesFolderName].logoSrc 
                ? <img className={styles.logo} src={projectsGallery[project.imagesFolderName].logoSrc}/> 
                : <h2 className={styles.logo}>{project.title}</h2>
            }</WebsiteWrapper>
            <div className={styles.summary}>
                <SummaryRow label="Statusas" value={project.status}/>
                <SummaryRow label="Statybos metai" value={project.build.toString()}/>
                <SummaryRow label="Plotas" value={`${project.area} kv.m.`}/>
                <SummaryRow label="Vieta" value={project.address}/>
                <SummaryRow label="Tipas" value={project.purpose}/>
            </div>
            <WebsiteWrapper url={project.website} displayChildrenIfNoUrl={false} className={classNames("navlink", styles.link)}>Sužinoti daugiau</WebsiteWrapper>
            <div className={styles.gallery}>
                <Gallery images={Object.values(projectsGallery[project.imagesFolderName].images)}/>
            </div>
        </ContentContainer>
  )
};

export default Project;

const SummaryRow: FC<{label: string, value: string}> = ({label, value}) => (
    <div className={styles.summary_row}>
        <div className={styles.summary_label}>{label}</div>
        <div className={styles.summary_value}>{value}</div>
    </div>
)

const WebsiteWrapper: FC<{url?: string, className?: string, children: ReactNode, displayChildrenIfNoUrl: boolean}> = ({url, className, children, displayChildrenIfNoUrl}) => {
    if(!url && !displayChildrenIfNoUrl)
        return <></>;

    if(url)
        return <a href={url} className={classNames("navlink", className)} target='_blank' rel="noreferrer">{children}</a>

    return <>{children}</>;
}
const Gallery: FC<{images: string[]}> = ({images}) => (
    <PhotoProvider>
    {
        images.map((item, index) => {
            return (
                <PhotoView key={index} src={item}>
                    <img className={styles.picture} src={item} />
                </PhotoView>
            )
        })
    }
    </PhotoProvider>
)