import React from 'react'
import PageHeader from '../components/PageHeader';
import defaultImage from "../resources/images/sendvariomiestas/1_Post-min.jpg"
import Services from '../components/Services';

const ServicesPage: React.FC = () => {
    return (
        <>
            <PageHeader header="Paslaugos" imageSrc={defaultImage} />
            <Services/>
        </>
    )
}

export default ServicesPage;
