import React, { FC, ReactNode } from 'react';
import styles from './ContentContainer.module.css';
import classNames from 'classnames';

const ContentContainer: FC<{wrapperClassName?: string, contentClassName?: string, children: ReactNode, hasMaxWidth?: boolean, hasGradientBg?: boolean}> = (props) => {
   return (
      <div className={classNames(styles.wrapper, props.wrapperClassName, {"gradient-bg": props.hasGradientBg}, {[styles.defaultWidth]: props.hasMaxWidth})}>
         <div className={classNames(styles.content, props.contentClassName)}>
            {props.children}
         </div>
      </div>
   );
};

export default ContentContainer;