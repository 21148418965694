import React from 'react';
import styles from './ProjectOverview.module.css';
import { ProjectOverview as ProjectOverviewType} from '../../../types/projects';
import { ROUTES } from '../../../resources/routes-constants';
import classNames from 'classnames';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { RiMapPin2Line } from "react-icons/ri";
import projectsGallery from '../../../resources/images/projectsGallery';

interface Props {
   project: ProjectOverviewType
}
const ProjectOverview = ({project}: Props) => {
   const navigate = useNavigate();
   const route = ROUTES.PROJECTS + "/" + project.url;
   const open:React.MouseEventHandler<HTMLElement> = (e) => {
      navigate(route);
   }

   return (
      <div className={styles.wrapper}>
         <div className={styles.background} onClick={open} style={{backgroundImage: `url(${projectsGallery[project.imagesFolderName].coverSrc})`}}/>
         <div className={styles.header}>
            <div className={styles.status}>
               {project.status}
            </div>
            <img className={styles.image} onClick={open} src={projectsGallery[project.imagesFolderName].coverSrc}/>
            <h3 className={styles.title} onClick={open} >  
               {project.title}
            </h3>
            <a className={styles.address} href={project.mapsLink} target="_blank" rel="noreferrer">
               <RiMapPin2Line/>
               <span className={styles.address_text}>
                  {project.address}
               </span>
            </a>
         </div>
         <div className={styles.summary} onClick={open}>
         {
            project.summary.map((summary, idx) => <span key={idx}>- {summary}</span>)
         }
         </div>
         <NavLink className={classNames(styles.more, "navlink")} to={route}>
            Skaityti daugiau →
         </NavLink>
      </div>
   )
};

export default ProjectOverview;