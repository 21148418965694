import React, { FC } from 'react';
import styles from './Logo.module.css';
import { ROUTES } from '../../resources/routes-constants';
import logo from "../../resources/images/logo.svg"
import classNames from 'classnames';

const Logo: FC<{className?: string}> = ({className}) => {
   return (
      <a href={ROUTES.HOMEPAGE_ROUTE} className={classNames(styles.logo, className)}>
         <img alt="Augama Real Estate" src={logo} className={styles.img}/>
      </a>
   );
};

export default Logo;