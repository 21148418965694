import React from 'react';
import styles from './Contacts.module.css';
import { TfiEmail, TfiLocationPin } from "react-icons/tfi";
import { PiFacebookLogoLight, PiPhoneCallLight } from "react-icons/pi";

const Contacts = () => {
   return (
      <>
            <div className={styles.contacts}>
               <a href="tel:+37067619464" className={styles.contactsGroup}>
                  <PiPhoneCallLight className={styles.contactsGroup_icon}/>
                  <p className={styles.contactsGroup_label}>Tel.Nr.</p>
                  <p className={styles.contactsGroup_value}>+37067619464</p>
               </a>
               <a href="mailto:info@augama.lt"  className={styles.contactsGroup}>
                  <TfiEmail className={styles.contactsGroup_icon}/>
                  <p className={styles.contactsGroup_label}>El.paštas</p>
                  <p className={styles.contactsGroup_value}>info@augama.lt</p>
               </a>
               <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/K.+Donelai%C4%8Dio+g.+19A,+Klaip%C4%97da,+92141+Klaip%C4%97dos+m.+sav./@55.7169881,21.1297768,16.25z/data=!4m6!3m5!1s0x46e4dbe348bffd43:0x6563a7c27a641336!8m2!3d55.7167158!4d21.1341139!16s%2Fg%2F11cp5q4mnq?hl=en-US&entry=ttu" className={styles.contactsGroup}>
                  <TfiLocationPin className={styles.contactsGroup_icon}/>
                  <p className={styles.contactsGroup_label}>Adresas</p>
                  <p className={styles.contactsGroup_value}>
                     <span>K.Donelaičio g. 19A</span>
                     <span>LT-92228, Klaipėda</span>
                  </p>
               </a>
               <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100086720790269" className={styles.contactsGroup}>
                  <PiFacebookLogoLight className={styles.contactsGroup_icon}/>
                  <p className={styles.contactsGroup_label}>Facebook</p>
                  <p className={styles.contactsGroup_value}>Augama</p>
               </a>
            </div>
      </>
   );
};

export default Contacts;