import React, { FC, ReactNode, useRef } from 'react';
import styles from './AboutUs.module.css';
import ContentContainer from '../ContentContainer';
import aboutUsImgSrc from './../../resources/images/apiemus.jpg'
import missionImgSrc from './../../resources/images/misija.jpg'
import visionImgSrc from './../../resources/images/vizija.jpg'
import classNames from 'classnames';
import useAnimateOnScolledToViewPort from '../../utility/useAnimateOnScolledToViewPort';
import sanitizeHtml from 'sanitize-html';

const SECTIONS = [{
   title: "Apie mus",
   imgSrc: aboutUsImgSrc,
   credits: <a href="https://www.vecteezy.com/free-photos/person">Person Stock photos by Vecteezy</a>,
   text: `
      Mes esame <b>nekilnojamojo turto investavimo, vystymo ir plėtros įmonė</b>, veikianti visoje Lietuvoje.
      <br/>Ne vienerius metus plėtojame nekilnojamojo turto projektus, kurių didžioji dauguma vakarų Lietuvoje, todėl galime pasiūlyti <b>aukščiausios kokybės paslaugas</b> savo klientams.
      <br/>Augama aktyviai dalyvauja kuriant <b>inovatyvius ir tvarius</b> komercinio nekilnojamojo turto projektus, siekdama prisidėti prie miestų plėtros ir verslo aplinkos tobulinimo.
   `
},
{
   title: "Misija",
   imgSrc: missionImgSrc,
   credits: <a href="https://www.vecteezy.com/free-photos/ai-generated">Ai Generated Stock photos by Vecteezy</a>,
   text: `
      Mūsų misija yra sukurti vertę ne tik sau, bet ir mūsų klientams bei bendruomenei.
      <br/> Mes siekiame įgyvendinti <b>aukštos kokybės</b> nekilnojamojo turto projektus, kurie atspindėtų mūsų viziją dėl inovacijų, darnaus vystymosi ir klientų poreikių tenkinimo.
      <br/>Mes siekiame būti ne tik <b>patikimi partneriai</b> savo klientams, bet ir <b>atsakingi</b> piliečiai, prisidedantys prie regiono socialinės ir ekonominės plėtros bei gerovės didinimo. Mūsų misija - kurti ne tik aukštos kokybės nekilnojamąjį turtą, bet ir stiprinti bendruomenės jausmą, <b>kuriant ilgalaikį tvarų įnašą į regiono augimą ir gyvenimo kokybę</b>.
   `
},
{
   title: "Vizija",
   imgSrc: visionImgSrc,
   credits: <a href="https://www.vecteezy.com/free-photos/two">Two Stock photos by Vecteezy</a>,
   text: `
      Mūsų vizija yra tapti <b>lydere Vakarų Lietuvoje</b> nekilnojamojo turto investavimo, vystymo ir plėtros srityje.
      <br/>Siekiame kurti ne tik fizinę, bet ir <b>socialinę bei ekonominę vertybę</b>, prisidedant prie regiono darnaus vystymosi.
      <br/>Stiprindami mūsų poziciją vakarų Lietuvoje, siekiame kurti <b>įspūdingus, kokybiškus bei inovatyvius</b> nekilnojamojo turto projektus, kurie taptų ne tik gyventojų stiliaus ir komforto simboliais, bet ir prisidėtų prie regiono <b>ekonomikos augimo skatinimo</b>.
   `
}]

const options = {
   allowedTags: [ 'b', 'br', 'a', 'ul', 'li' ],
   allowedAttributes: { 'a': [ 'href' ] },
};


const AboutUs = () => {
   return (
      <ContentContainer contentClassName={classNames(styles.about, 'section')}>
      {
         SECTIONS.map((section, idx) => (
            <div className={styles.section} key={idx}>
               <Image credit={section.credits} src={section.imgSrc} idx={idx}/>
               <div className={styles.description}>
                  <h3 className={styles.header}>
                     {section.title}
                  </h3>
                  <div className="text">
                  {
                     section.text.split("<br/>").map((descr, idx) => 
                        <span key={idx} dangerouslySetInnerHTML={{__html: sanitizeHtml(descr, options)}}/>
                     )
                  }
                  </div>
               </div>
            </div>
         ))
      }
      </ContentContainer>
   );
};

export default AboutUs;

const Image: FC<{credit:ReactNode, src: string, idx: number}> = ({credit, src, idx}) => {
   const ref = useRef<HTMLDivElement>(null)
   const animationClass = useAnimateOnScolledToViewPort(ref, idx % 2 !== 0 ? "fadeInRight": "fadeInLeft", false);

   return (
      <div ref={ref} className={classNames(styles.image, animationClass)}>
         <img src={src}/>
         {credit}
      </div>
   )
}