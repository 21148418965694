import { RefObject, useEffect, useState } from "react";
import useOnScreen from "./useOnScreen";
import getAnimationClassName from "./getAnimationClassName";

export default function useAnimateOnScolledToViewPort(ref: RefObject<HTMLElement>, animation: string, reaplyStyling: boolean) {
	const [className, setClassName] = useState<string | undefined>(undefined);
	const isVisible = useOnScreen(ref);
	
	useEffect(() => {
		if(isVisible && !className)
			setClassName(getAnimationClassName(animation));
		if(reaplyStyling && className && !isVisible)
			setClassName(undefined);
	}, [isVisible]);

	return className;
}