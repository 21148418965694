import React from "react";
import { gql, request } from "graphql-request";

const CONTENT_ENDPOINT = 'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cltsuniad02z407ur0rrq906k/master';

class BlogPostsApiService {
    getBlogPostsOverviews = async () => await request(
        CONTENT_ENDPOINT,
        gql`query 
            BlogPosts {
                blogPosts {
                    createdAt
                    id
                    urlId
                    slug
                    title
                    coverImage {
                        id
                        url
                    }
                }
            }
            
        `
    );

    getBlogPost = async (urlId: string) => await request(
        CONTENT_ENDPOINT,
        gql`query 
            BlogPosts {
                blogPost(where: {urlId: "${urlId}"}){
                    urlId
                    createdAt
                    id
                    slug
                    title
                    coverImage {
                        url
                    }
                    content{
                        html
                    }
                }
            }
        `
    );
}

export default new BlogPostsApiService();