import React, { FC } from 'react';
import styles from './BlogPost.module.css';
import sanitizeHtml from 'sanitize-html';
import { BlogPost as BlogPostType } from '../../../types/blogPosts';
import ContentContainer from '../../ContentContainer';

const options = {
   allowedTags: [ 'b', 'br' , 'img', 'p', 'strong'],
   allowedAttributes: {
     'a': [ 'href' ],
     'img': [ 'src', 'alt' ]
   },
   // allowedIframeHostnames: ['www.youtube.com']
};


const BlogPost: FC<{blogPost: BlogPostType}> = ({blogPost}) => {
   return (
      <ContentContainer hasMaxWidth={true} contentClassName={styles.content}>
         <div className={styles.date}>
            {new Date(blogPost.createdAt).toLocaleString('lt-LT', { year: "numeric", month: 'long' , day: 'numeric'})}
         </div>
         <h3 className={styles.slug}>
            {blogPost.slug}
         </h3>
         <div className={styles.blog} dangerouslySetInnerHTML={{__html: sanitizeHtml(blogPost.content.html, options)}}/>
      </ContentContainer>
   );
};

export default BlogPost;