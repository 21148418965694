import React, { FC, useState } from 'react';
import styles from './NavBar.module.css';
import { ROUTES } from '../../resources/routes-constants'
import Logo from '../Logo';
import { CiMenuBurger } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import classNames from 'classnames';
import getAnimationClassName from '../../utility/getAnimationClassName';
import useDeviceLayout from '../../utility/useDeviceLayout';
import { NavLink } from 'react-router-dom';
 
const NavBar: React.FC = () => {
    const isMobile = useDeviceLayout("MOBILE");


    return (
        <div className={styles.wrapper}>
            <Logo/>
            <div className={styles.navBar}>
                {isMobile ? <MobileNavigation/> : <DesktopNavigation/>}
            </div>
        </div>
    );
};

export default NavBar;

const DesktopNavigation = () => <NavRoutes/>
const MobileNavigation = () => {
    const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
    return (
        <>
            {!isOpen && <CiMenuBurger onClick={() => {setIsOpen(true);}} className={styles.toggleIcon}/>}
            {isOpen && <IoCloseOutline onClick={() => {setIsOpen(false);}} className={styles.toggleIcon}/>}
            <NavRoutes className={classNames({[styles.initialNav]: isOpen === undefined}, styles.mobileDropdown, getAnimationClassName(!isOpen ? "slideOutUp" : "slideInDown"))}/>
        </>
    )
}

type NavLinkRenderProps = {
    isActive: boolean;
    isPending: boolean;
    isTransitioning: boolean;
};

const NavRoutes: FC<{className?: string}> = ({className}) => {
    const getClassName: ((props: NavLinkRenderProps) => string | undefined) = ({ isActive }) => {
        const activeClass =  isActive ? styles.active : undefined;
        return classNames(activeClass, "navlink");
    }
       
    return (
        <header className={classNames(styles.nav, className)}>
            <NavLink to="/" className={getClassName}>Pradžia</NavLink>
            <NavLink to={ROUTES.ABOUT_US} className={getClassName}>Apie mus</NavLink>
            <NavLink to={ROUTES.SERVICES} className={getClassName}>Paslaugos</NavLink>
            <NavLink to={ROUTES.PROJECTS} className={getClassName}>Projektai</NavLink>
            <NavLink to={ROUTES.NEWS} className={getClassName}>Naujienos</NavLink>
            <NavLink to={ROUTES.CONTACTS} className={getClassName}>Kontaktai</NavLink>
        </header>
    )
}
