import React from 'react'
import Slider from '../components/Slider';
import AboutUs from '../components/AboutUs';
import Projects from '../components/Projects';
import News from '../components/News';
import Services from '../components/Services';
import Contacts from '../components/Contacts';
import ContentContainer from '../components/ContentContainer';

const HomePage: React.FC = () => {
    return (
        <>
            <Slider/>
            <AboutUs/>
            <ContentContainer hasMaxWidth={true} wrapperClassName='section'>
                <h1 className='lined-header'>Projektai</h1>
                <Projects/>
            </ContentContainer>
            <ContentContainer wrapperClassName='section' hasGradientBg={true} hasMaxWidth={true}>
                <h1 className='lined-header'> Paslaugos</h1>
                <Services/>
            </ContentContainer>
            <ContentContainer hasMaxWidth={true} wrapperClassName='section'>
                <h1 className='lined-header'>Naujienos</h1>
                <News/>
            </ContentContainer>
            <ContentContainer hasGradientBg={true} hasMaxWidth={true} wrapperClassName='section'>
                <h1 className='lined-header'>Susisiekite</h1>
                <Contacts/>
            </ContentContainer>
        </>
    )
}

export default HomePage
