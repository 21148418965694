export enum PROJECT_IMAGES_FOLDERS {
    P6 = "p6",
    SendvarioMiestas = "sendvariomiestas",
    Tilzes52F = "tilzes52f"
}

export interface ProjectOverview {
    id: string,
    url: string,
    status: PROJECT_STATUS, 
    title: string,
    address: string,
    mapsLink: string,
    summary: string[],
    imagesFolderName: PROJECT_IMAGES_FOLDERS,
    purpose: PROJECT_PURPOSE_TYPE
}

export interface Project extends ProjectOverview {
    description: string,
    build: number | string,
    area: number,
    website?: string,
}

export enum PROJECT_STATUS {
    PLANNED = "Planuojamas",
    FOR_RENT = "Nuomojama",
    IN_PROGRESS = "Statomas",
    DONE = "Įgyvendintas"
}
 
export enum PROJECT_PURPOSE_TYPE {
    BUSINESS = "Verslui",
    LIVING = "Gyvenimui",
}
 