import React from "react";
import { PROJECT_IMAGES_FOLDERS, PROJECT_PURPOSE_TYPE, PROJECT_STATUS, Project, ProjectOverview } from "../types/projects";

// const CONTENT_ENDPOINT = 'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cltsuniad02z407ur0rrq906k/master';
const PROJECTS: Project[] = [
    {
        status: PROJECT_STATUS.FOR_RENT,
        purpose: PROJECT_PURPOSE_TYPE.BUSINESS, 
        url: "Sendvario miestas".replaceAll(" ", "-").toLowerCase(),
        build: 2024,
        area: 9000,
        website: "https://sendvariomiestas.lt",
        id: "sendvariomiestas",
        title: "Sendvario miestas",
        address: "Mokyklos g. 33, Klaipėda",
        imagesFolderName: PROJECT_IMAGES_FOLDERS.SendvarioMiestas,
        summary: [
            "Šis kompleksas sudarytas iš šešių korpusų;",
            "Bendras plotas siekia net 9 000 m². Tai reiškia, kad čia yra gausybė erdvės ir galimybių, kurios gali būti pritaikytos visų tipų įmonėms. ",
            "Čia įsikurs restoranai ir kavinės, grožio salonai, įvairaus spektro parduotuvės"
        ],
        mapsLink: "https://www.google.com/maps/place/Mokyklos+g.+33,+Klaip%C4%97da,+91260+Klaip%C4%97dos+m.+sav./",
        description: `
            Sendvario miestas yra komercinių patalpų <b>kompleksas</b>, kuriame Jūsų verslas turės visas galimybes <b>suklestėti</b>.
            Dėl savo istorijos, modernių sprendimų ir komplekse planuojamų rengti renginių, tai bus viena lankomiausių vietų Klaipėdoje.
            Taigi nepraleiskite šanso savo verslą įkurti čia! <br/>
            Šis kompleksas sudarytas iš šešių korpusų, o jo bendras plotas siekia net 9000 m².
            Tai reiškia, kad čia yra gausybė erdvės ir galimybių, kurios gali būti pritaikytos visų tipų įmonėms.
            <br/> Čia įsikurs restoranai ir kavinės, grožio salonai, įvairaus spektro parduotuvės, šunų kirpykla ir bus teikiamos 
            veterinarijos paslaugos, vyks vaikų būreliai, veiks mokyklėlės ir žaidimų kambariai, gydymo įstaigos ir be 
            abejo - biurų erdvės. <br/> Komplekse veiks net kelios skirtingų dydžių konferencijų salės, kurias galima bus transformuoti pagal Jūsų poreikius.
            Taip pat parke bus įrengta renginiams skirta scena.
        `
    },{
        status: PROJECT_STATUS.FOR_RENT,
        purpose: PROJECT_PURPOSE_TYPE.BUSINESS, 
        url: "p6",
        build: "2024 III ketv.",
        area: 555,
        imagesFolderName: PROJECT_IMAGES_FOLDERS.P6,
        id: "p6",
        title: "P6",
        address: "Priestočio g. 6, Klaipėda",
        summary: ["Plotas: 555kv.m.", 'Paskirtis: administracinės paskirties patalpos', 'Ypatybės: keturių aukštų pastatas, A+ energetinės klasės', 'Darbų vykdymo data: 2024 m.'],
        mapsLink: "https://www.google.com/maps/place/Priesto%C4%8Dio+g.+6,+Klaip%C4%97da,+92227+Klaip%C4%97dos+m.+sav.",
        description: `
        P6 verslo centras - administracinių patalpų kompleksas <b>inovatyviam</b> ir <b>šiuolaikiškam</b> 
        verslo vystymui. Komercinių patalpų ir biurų kompleksą, įsikūrusį <b>Klaipėdos senamiestyje</b>, sudaro vienas pastatas, po kuriuo bus požeminė automobilių stovėjimo aištelė. 
        <br/>Plotas: 555kv.m.
        <br/>Paskirtis: administracinės paskirties patalpos
        <br/>Ypatybės: keturių aukštų pastatas, A+ energetinės klasės
        <br/>Darbų vykdymo data: 2024 m.`
    },{
        status: PROJECT_STATUS.PLANNED,
        purpose: PROJECT_PURPOSE_TYPE.BUSINESS, 
        url: "Tilzes 52F".replaceAll(" ", "-").toLowerCase(),
        build: 2025,
        area: 3350,
        id: "tilzes52f",
        title: "Tilžės 52F",
        address: "Tilžės g. 52F, Klaipėda",
        imagesFolderName: PROJECT_IMAGES_FOLDERS.Tilzes52F,
        summary: [
            "Plotas: 3350kv.m.", 
            "Paskirtis: prekybos ir administracinės paskirties patalpos",
            "Ypatybės: šešių aukštų pastatas, A+ energetinės klasės",
            "Darbų vykdymo data: 2025 m."
        ],
        mapsLink: "https://www.google.com/maps/place/Tilzes+g.+52f,+Klaip%C4%97da,+91260+Klaip%C4%97dos+m.+sav./",
        description: `
        Naujas šešių aukštų verslo centras, adresu Tilžės g. 52F, Klaipėda, kurio plotas sieks 3350 kvadratinių metrų, bus dedikuotas <b>biurų ir prekybos</b> funkcijoms. Pirmasis aukštas bus skirtas prekybai, suteikiant plačias galimybes verslo ir prekybos sektoriui. Kitų aukštų patalpos bus skitos administracinei veiklai.
        Šis naujas verslo centras atsiras <b>miesto centre</b>, suteikiant lengvą priėjimą tiek automobiliu, tiek viešuoju transportu. Tai bus nauja perspektyvi vieta, kurioje susitiks modernus dizainas ir funkcionalumas.
        Sekite naujienas, kad sužinotumėte daugiau apie šį projektą ir jo plėtros eigą.
        <br/>
        <br/>Plotas: 3350kv.m.
        <br/>Paskirtis: prekybos ir administracinės paskirties patalpos
        <br/>Ypatybės: šešių aukštų pastatas, A+ energetinės klasės
        <br/>Darbų vykdymo data: 2025 m.
        `
    }];

class ProjectsApiService {
    getProjectsOverviews = async () => {
        return await PROJECTS as ProjectOverview[];
    }

    getProject = async (url: string) => {
        return await PROJECTS.find(x => x.url === url)!;
    }
}

export default new ProjectsApiService();