import React from 'react'
import PageHeader from '../components/PageHeader';
import defaultImage from "../resources/images/sendvariomiestas/VIZ_10_C-min.jpg"
import ContentContainer from '../components/ContentContainer';
import News from '../components/News';

const NewsPage: React.FC = () => {      
    return (
        <>
            <PageHeader header="Naujienos" imageSrc={defaultImage} />
            <ContentContainer hasMaxWidth={true}>
                <News/>
            </ContentContainer>
        </>
    )
}

export default NewsPage;
