import React from 'react'
import { ROUTES } from '../resources/routes-constants'
import PageHeader from '../components/PageHeader'
import img from '../resources/images/sendvariomiestas/VIZ_6-min.jpg'

const NotFoundPage: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '32px',
        }}>
            <PageHeader header='Puslapis nerastas. 404' imageSrc={img} />
            <a href={ROUTES.HOMEPAGE_ROUTE} className='navlink'>
                <span>Grįžti į pagrindinį puslapį</span>
                <span>→</span>
            </a>
        </div>
    )
}

export default NotFoundPage
