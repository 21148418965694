function importAllImages(context: __WebpackModuleApi.RequireContext) {
    let logoSrc: string | undefined = undefined; 
    let coverSrc: string | undefined = undefined; 
    const images = context.keys().reduce(
            (result:  {[key: string]: string}, value) => {
                let path = context(value);
                if(path[0] === '.')
                    path = path.substring(1);

                path = window.location.origin + path;
                if(value.includes('logo'))
                    logoSrc = path ;
                else if(value.includes('cover'))
                    coverSrc = path;
                else result[value.replace('./', '')] = path;
            
                return result;
            }, {}
    );

    return {
        logoSrc,
        images,
        coverSrc
    };
}
   

const P6 = importAllImages(require.context('./p6', false, /\.(png|jpe?g|svg)$/));
const sendvariomiestas = importAllImages(require.context('./sendvariomiestas', false, /\.(png|jpe?g|svg)$/))
const tilzes52f = importAllImages(require.context('./tilzes52f', false, /\.(png|jpe?g|svg)$/))

export default ({
    p6: P6,
    sendvariomiestas: sendvariomiestas,
    tilzes52f: tilzes52f
})