import React from 'react'
import AboutUs from '../components/AboutUs';
import PageHeader from '../components/PageHeader';
import defaultImage from "../resources/images/sendvariomiestas/3_Post-min.jpg"

const AboutUsPage: React.FC = () => {
    return (
        <>
            <PageHeader header="Apie mus" imageSrc={defaultImage} />
            <AboutUs/>
        </>
    )
}

export default AboutUsPage
