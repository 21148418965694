import React, { FC, useEffect, useMemo } from 'react';
import styles from './Projects.module.css';
import ProjectOverview from './ProjectOverview';
import { useAppDispatch, useAppSelector } from '../../store/reducers/store';
import { loadProjectsOverviews } from '../../store/actions';

const Projects:FC<{purpose?: string}> = ({purpose}) => {
   const dispatch = useAppDispatch();
   const allProjects = useAppSelector(state => state.projects.overviews);
   
   useEffect(() => {
      dispatch(loadProjectsOverviews());
   }, [])

   const projects = useMemo(() => 
      purpose ? allProjects.filter(x => x.purpose === purpose) : allProjects,
      [allProjects, purpose]
   )

   if(projects?.length === 0)
      return <div style={{textAlign: "center"}}>Projektai ruošiami...</div>

   return (
      <div className={styles.list}>
      {
         projects.map((proj) => <ProjectOverview key={proj.title} project={proj}/>)
      }
      </div>
   );
};

export default Projects;
