import React, { useEffect } from 'react'
import PageHeader from '../components/PageHeader';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/reducers/store';
import { getProject } from '../store/actions';
import Project from '../components/Projects/Project';
import projectsGallery from '../resources/images/projectsGallery';

const ProjectPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const params = useParams<{urlId:string}>();
    const project = useAppSelector(state => state.projects.projects?.find(x => x.url === params.urlId ));

    useEffect(() => {
        if(!project)
            dispatch(getProject(params.urlId!));
    }, [project])

    if(!project)
        return <></>
    
    return (
        <>
            <PageHeader header={project.title} imageSrc={projectsGallery[project.imagesFolderName].coverSrc!} subheader={project.address}/>
            <Project project={project}/>
        </>
    )
}

export default ProjectPage;
