import React, { ReactNode } from 'react';
import styles from './PageHeader.module.css';

interface Props {
   imageSrc: string,
   header: string,
   subheader?: string
}

const PageHeader = (props: Props) => {
   return (
      <div className={styles.wrapper}>
         <img className={styles.image} src={props.imageSrc}/>
         <h1 className={styles.header}>{props.header}</h1>
         {props.subheader && <div className={styles.subheader}> {props.subheader}</div>}
      </div>
   );
};

export default PageHeader;