import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import { ROUTES } from './resources/routes-constants';
import NavBar from './components/NavBar';
import AboutUsPage from './pages/AboutUsPage';
import ServicesPage from './pages/ServicesPage';
import ProjectsPage from './pages/ProjectsPage';
import Footer from './components/Footer';
import ProjectPage from './pages/ProjectPage';
import BlogPostPage from './pages/BlogPostPage';
import NewsPage from './pages/NewsPage';
import ContactsPage from './pages/ContactsPage';

export const RootComponent: React.FC = () => {

    return (
        <Router>
            <NavBar />
            <ScrollToTop/>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                <Route path={ROUTES.ABOUT_US} element={<AboutUsPage />} />
                <Route path={ROUTES.SERVICES} element={<ServicesPage />} />
                <Route path={ROUTES.PROJECTS} element={<ProjectsPage />} />
                <Route path={ROUTES.PROJECT} element={<ProjectPage />} />
                <Route path={ROUTES.NEWS} element={<NewsPage />} />
                <Route path={ROUTES.BLOG_POST} element={<BlogPostPage />} />
                <Route path={ROUTES.CONTACTS} element={<ContactsPage />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}