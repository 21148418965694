import React from 'react';
import styles from './Footer.module.css';
import Logo from '../Logo';

const Footer = () => {
   return (
      <div className={styles.wrapper}>
         {/* <Logo className={styles.logo}/>
         <div className={styles.nav}></div>
         <div className={styles.contacts}></div> */}
         {/* <div className="line-h"/> */}
         <div className={styles.rights}>Visos teisės saugomos {new Date().getFullYear()} © augama.lt</div>
      </div>
   );
};

export default Footer;