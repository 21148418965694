import { createReducer } from '@reduxjs/toolkit'
import pht2 from "../../resources/images/sendvariomiestas/VIZ_7-min.jpg"
import pht5 from "../../resources/images/sendvariomiestas/VIZ_13 - Photo-min.png"
import pht4 from "../../resources/images/p6/1.3.jpg"
import pht6 from "../../resources/images/p6/1.1.jpg"
import pht7 from "../../resources/images/tilzes52f/viz3.jpg"
import pht8 from "../../resources/images/tilzes52f/viz1.cover.jpg"

interface HomeReducer {
    images: {src: string, title?: string}[],
}

const initialState: HomeReducer = {
    images: [
        {src: pht2},
        {src: pht4},
        {src: pht7},

        {src: pht5},
        {src: pht6},
        {src: pht8}
    ]
}

const homeReducer = createReducer<HomeReducer>(initialState, (builder) => {
    //do nothing
})

export default homeReducer
