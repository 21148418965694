import React from 'react'
import PageHeader from '../components/PageHeader';
import defaultImage from "../resources/images/sendvariomiestas/VIZ_10_C-min.jpg"
import Contacts from '../components/Contacts';

const ContactsPage: React.FC = () => {      
    return (
        <>
            <PageHeader header="Kontaktai" imageSrc={defaultImage} />
            <Contacts/>
        </>
    )
}

export default ContactsPage;
